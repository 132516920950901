import { createNode, is, step, } from 'effector';
export const LOGGER_DOMAIN_NAME = '@effector-logger';
export function createName(composite) {
    return composite.path.filter((name) => name !== LOGGER_DOMAIN_NAME).join('/');
}
export function getPath(unit) {
    var _a, _b, _c;
    return (_c = (_b = (_a = unit.defaultConfig) === null || _a === void 0 ? void 0 : _a.loc) === null || _b === void 0 ? void 0 : _b.file) !== null && _c !== void 0 ? _c : ' ';
}
function watchDomain(unit, domain, fn) {
    if (is.store(unit)) {
        fn(unit.getState());
    }
    const watchUnit = is.store(unit) ? unit.updates : unit;
    watchUnit.watch(fn);
}
function watchScope(unit, scope, fn) {
    if (is.store(unit)) {
        fn(scope.getState(unit));
    }
    const watchUnit = is.store(unit) ? unit.updates : unit;
    createNode({
        node: [step.run({ fn })],
        parent: scope.find(watchUnit),
    });
}
export function watch(unit, source, fn) {
    if (is.domain(source)) {
        watchDomain(unit, source, fn);
    }
    else {
        watchScope(unit, source, fn);
    }
}
